<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <div
          class="mb-7 ml-5"
        >
          Email Suppression Code:
          <div class="mt-2">
            <v-chip color="success">
              <span class="font-weight-bold">{{ $route.query.sc }}</span>
            </v-chip>
          </div>
        </div>
        <v-card-subtitle>
          Select email suppression file to upload.
        </v-card-subtitle>
      </v-col>
    </v-row>
    <v-divider />
    <v-row>
      <v-col>
        <v-card-title> Upload File </v-card-title>
        <v-row>
          <v-col cols="4">
            <v-file-input
              v-model="emailSuppressionFile"
              dense
              clearable
              show-size
              accept=".csv, .txt"
              :rules="emailSuppressionRules"
              label="Choose File"
              @change="clearErrors()"
            />
          </v-col>
          <v-col>
            <small
              v-if="uploadError !== ''"
              class="ml-10 mb-5"
              style="color: red"
            >
              {{ uploadError }}
            </small>
          </v-col>
        </v-row>
        <div class="font-italic pl-7 inline-block">
          Valid file extensions: .csv, .txt
        </div>
        <v-card-actions>
          <v-btn
            color="success"
            :loading="uploadingFiles"
            :disabled="emailSuppressionFile === null || !isValidTypeFile"
            @click="validateUpload()"
          >
            Upload file
          </v-btn>
          <v-btn
            color="tertiary"
            @click="cancel()"
          >
            cancel
          </v-btn>
        </v-card-actions>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  name: 'EmailSuppressionFileUpload',

  props: {
    suppressionCode: {
      type: String,
      required: true
    },
    stepVal: {
      type: Number,
      default: 2
    }
  },

  data () {
    return {
      results: [],
      uploadResults: [],
      valid: false,
      isSingleColumn: false,
      isValidTypeFile: true,
      emailSuppressionFile: null,
      fileData: '',
      row: '',
      row0: '',
      fileArray: [],
      emailSuppressionRules: [
        value =>
          !value ||
              value.size < 11000000 ||
              `${vm.contactType} File size should be less than 11 MB`
      ],
      resultsLoading: false,
      uploadingFiles: false,
      uploadError: ''
    }
  },

  computed: {
    ...mapGetters(['viewAsCustomer', 'simulatedCustomerCrmId']),
    ...mapGetters('auth', [
      'isMMSRole',
      'getTenantId',
      'getTenantCrmId'
    ]),
    showViewAsCustomer () {
      return this.$store.getters['auth/isMMSRole']()
    },
    isLoading () {
      return this.loadingCount > 0
    }
  },

  watch: {
    stepCounter (newValue, oldValue) {
      if (this.results.length !== 0 & newValue !== undefined) {
        this.getDrLookup()
      }
    }
  },

  created () {
  },

  methods: {
    clearErrors () {
      if (this.emailSuppressionFile !== null) {
        if (this.emailSuppressionFile.type.match('text/csv') || this.emailSuppressionFile.type.match('text/plain')) {
          this.uploadError = ''
          this.isValidTypeFile = true
        } else {
          this.uploadError = 'This is not a valid CSV or TXT file'
          this.isValidTypeFile = false
        }
      }
    },
    cancel () {
      this.$router.push({ path: 'suppression/emailSuppression' })
    },
    async validateUpload () {
      var _this = this
      this.uploadError = ''

      var reader = new FileReader()
      reader.readAsText(this.emailSuppressionFile)
      reader.onload = async () => {
        _this.fileData = reader.result
        var newLine = '\r\n'
        _this.fileArray = _this.fileData.split(newLine)
        _this.row0 = _this.fileArray[0].split(/[,\t]/).toString()
        if (_this.uploadError === '') {
          _this.uploadingFiles = true
          _this.uploadFile()
        }
      }
    },
    async uploadFile () {
      var _this = this
      _this.resultsLoading = true
      var formData = new FormData()
      var customerNowwId = this.$store.getters['simulatedCustomerNowwId']()
      var emailSuppressionId = _this.$route.query.sid
      formData.append('nowwId', customerNowwId)
      formData.append('emailSuppressionId', emailSuppressionId)
      formData.append('files[]', _this.emailSuppressionFile)

      _this.resultsLoading = false
      _this.$emit('advanceFromStep', _this.stepVal)
      _this.$emit('row0', _this.row0)
      _this.$emit('fileArray', _this.fileArray)
      _this.$emit('fileData', _this.fileData)
      _this.uploadingFiles = false
    }
  }
}
</script>
